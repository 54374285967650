//Global variables
window.lm = {};
// window.ajaxCall = [];

// Commanders act variable for tracking system
window.tc_vars = {};
import 'integration-web-core--socle/js/assets/commons/_delegateEvent.js';
import { activeModules, kobiEvents, Composant } from 'integration-web-core--socle/js/assets/commons/_common.js';
import { lmFetch } from 'integration-web-core--socle/js/assets/commons/_fetch.js';
import { cookie } from 'integration-web-core--socle/js/assets/commons/_cookie.js';
import { localStorageLM } from 'integration-web-core--socle/js/assets/commons/_localStorage.js';
import { template } from 'integration-web-core--socle/js/assets/commons/_template.js';
import { DOMReady } from 'integration-web-core--socle/js/assets/commons/_domReady.js';
import { init as initLazyload } from 'integration-web-core--socle/js/assets/commons/_lazyload.js';
import { EventBus, IntersectionObserverService } from '@adeo/kobi--front-helpers';

//Polyfill for Math.sign
if (!Math.sign) Math.sign = function (x) {
  return (x > 0) - (x < 0) || +x;
};

// Expose needed stuff to lm global variables
lm.Composant = Composant;
lm.modules = activeModules;
lm.events = kobiEvents;
lm.fetch = lmFetch;
lm.cookie = cookie;
lm.localStorageLM = localStorageLM;
lm.template = template;
lm.lazyload = null;
lm.DOMReady = DOMReady;
class Sentry {
  configureScope() {
    return null;
  }
}
lm.sentry = new Sentry();

// Init localStorageLM inside global variable
lm.localStorageLM.init();
lm.DOMReady(() => {
  // Init lazy-loading
  lm.lazyload = initLazyload('.lazy, [loading=lazy]');
  lm.lazyload.observe();
  const cssAsync = document.querySelectorAll('link.lazy-src');
  for (let cAsync of cssAsync) {
    cAsync.href = cAsync.dataset.href;
  }
  const jsAsync = document.querySelectorAll('script.lazy-src');
  for (let jAsync of jsAsync) {
    jAsync.src = jAsync.dataset.src;
  }
});

// Keep track of common.js execution through webperf reporting
if (performance.mark !== undefined) {
  performance.mark('Common.js has run');
}
lm.MobileAppBridge = function (action, value) {
  return new Promise((res, rej) => {
    // Android part
    if (typeof CustomerMobileAppWebBridge === 'object') {
      res(CustomerMobileAppWebBridge.sendEvent(action, value));
    }

    // iOS part
    else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.customerMobileAppSendEvent) {
      var message = {
        command: action,
        parameters: {
          'param': value
        }
      };
      window.webkit.messageHandlers.customerMobileAppSendEvent.postMessage(message).then(() => res(true)).catch(() => rej());
    } else res(false);
  });
};
const eventBus = new EventBus('NewsLetterPopin');
const NewsLetterPopin = {
  /**
   * Set the condition to show the popin
   * @param {Object} data - Data to be sent to the news letter popin component
   * @example lm.NewsLetterPopin.setTrigger({type: 'callingShort'}) // Show the popin after a short time (10s)
   * @example lm.NewsLetterPopin.setTrigger({type: 'callingLong'}) // Show the popin after a long time (30s)
   */
  setTrigger(data) {
    eventBus.emit('triggerPopinCondition', data);
  },
  /**
   * Add a listener to the event bus to implement the logic to show the popin
   * @param {Function} callback - Function to be called when the popin is shown
   * @example lm.NewsLetterPopin.listening((data) => { console.log(data) }) // Log the data sent to trigger the popin
   */
  listening(callback) {
    eventBus.on('triggerPopinCondition', data => callback(data.detail));
  }
};
lm.NewsLetterPopin = NewsLetterPopin;
if (!window.fragmentLazyLoad) {
  window.fragmentLazyLoad = new IntersectionObserverService({
    root: null,
    rootMargin: '10%',
    threshold: 0.0
  }) // Intersection Observer API options
  ;
  window['lazy-loading-bus'].on("fragmentLoaded", () => {
    lm.lazyload = initLazyload('.lazy, [loading=lazy]');
    lm.lazyload.observe();
  });
}